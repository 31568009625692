import {API_URL} from "../config";

const usersUrl = ({session, archived = false, all = false}) => {
    if(session) {
        if (!!all && all === true)
            return `${API_URL}users?session=${session}&all=true`
        return !!archived ? `${API_URL}users?session=${session}${archived ? '&archived=false' : ''}`
            : `${API_URL}users?session=${session}`
    }
}

const userDataUrl = (session, id) => {
    return `${API_URL}sessions/${session}/users/${id}`
}

const archiveUserUrl = (session, id, archive) => {
    return archive ? `${userDataUrl(session, id)}/archive` : `${userDataUrl(session, id)}/unarchive`
}

const resendEmailToUserUrl = (session, id, emailType) => {
    return `${userDataUrl(session, id)}/resend-invitation${emailType ? ('/'+emailType) : ''}`
}

const UserService = {
    usersUrl,
    userDataUrl,
    archiveUserUrl,
    resendEmailToUserUrl
};

export default UserService;
