import React, {useContext, useState} from "react";
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, FormGroup,
    FormHelperText,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Help, Save} from "@mui/icons-material";
import {some} from "lodash";
import axios from "axios";
import UserService from "../../services/user.service";
import {GlobalContext} from "../../state/global";
import {API_URL} from "../../config";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../../components/CustomTooltip";

const AddUserDialog = ({dialogState, handleClose, formId, setError}) => {
    const [globalState, _] = useContext(GlobalContext)

    const [isValidEmail, setIsValidEmail] = useState(false)
    const [sendEmail, setSendEmail] = useState(false)
    const [userData, setUserData] = useState({
        email: '',
        nome: '',
        cognome: '',
        concessionaria: '',
        slotWebinar: ''
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log("event:",event)
        const fieldId = event.target.id || event.target.name
        if(fieldId === 'email') {
            setIsValidEmail(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value))
        }

        setUserData({...userData, [fieldId]: event.target.value});
    };

    const handleSubmit = () => {
        axios({
            url: `${API_URL}sessions/0524/users/${formId}?sendEmail=${sendEmail}`,
            method: 'POST',
            data: {...userData, email: userData.email.trim().toLowerCase()},
        })
            .then((res) => {
                console.log("res:",res)
                if(res) {
                    setUserData({
                        email: '',
                        nome: '',
                        cognome: '',
                        concessionaria: '',
                        slotWebinar: ''
                    })
                    handleClose({status: 'success', userId: res.data})
                }
            })
            .catch((err) => {
                setError(err.response?.data?.error || "Inserimento non andato a buon fine")
                console.log("handleSubmit new user --> err:", err)
            })
    }

    return (
        <Dialog open={dialogState.open} onClose={handleClose} maxWidth={'xs'} fullWidth
                PaperProps={{variant: 'dialog'}} scroll={'paper'}>
            <DialogTitle>{'Aggiungi un nuovo partecipante'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="E-mail"
                    type="email"
                    fullWidth
                    variant="standard"
                    required
                    value={userData.email}
                    onChange={handleChange}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="nome"
                    label="Nome"
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                    value={userData.nome}
                    onChange={handleChange}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="cognome"
                    label="Cognome"
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                    value={userData.cognome}
                    onChange={handleChange}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="concessionaria"
                    label="Concessionaria"
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                    value={userData.concessionaria}
                    onChange={handleChange}
                />
                <FormControl variant="standard" fullWidth>
                    <FormHelperText>Slot Webinar</FormHelperText>
                    <Select
                        label={"Slot Webinar"}
                        id={"slotWebinar"}
                        name={"slotWebinar"}
                        //value={dati[field.id]}
                        value={userData.slotWebinar}
                        onChange={handleChange}
                        //onBlur={formik.handleBlur}
                        //error={Boolean(formik.errors[field.id])}
                        //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                        //helperText={field.label}
                    >
                        {(Object.entries(globalState?.settings?.webinars||[])).map(([key, value], i) =>
                            <MenuItem value={key} key={key}>{value.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                {<FormControl sx={{mt: 2}}>
                    <FormGroup row sx={{alignItems: 'center'}}>
                        <FormControlLabel
                            control={<Checkbox
                                id={'invio-email'}
                                name={'invio-email'}
                                checked={sendEmail}
                                onChange={(event, checked) => {
                                    setSendEmail(checked)
                                }}
                            />} label={'Invia email di registrazione'}/>
                        <CustomTooltip title={"Verrà inviata un'email automatica per permettere la registrazione dell'utente inserito"}
                                       children={<IconButton size={'small'}><Help color={'disabled'} fontSize={'small'}/></IconButton>}/>
                    </FormGroup>
                </FormControl>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button variant={'submit'} startIcon={<Save/>}
                        disabled={!isValidEmail || (some(Object.values(userData), function (o) {
                            return !o
                        }))}
                        onClick={handleSubmit}>
                    Aggiungi
                </Button>

            </DialogActions>
        </Dialog>
    );
}

export default AddUserDialog