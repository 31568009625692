import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBKRO7gMWS5Ox6yf3Bwm8Mfb5uDw8iJngk",
    authDomain: "dacia-empowerment-23.firebaseapp.com",
    projectId: "dacia-empowerment-23",
    storageBucket: "dacia-empowerment-23.appspot.com",
    messagingSenderId: "552039550845",
    appId: "1:552039550845:web:69be8021232e3574f08614"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}