import React from "react";
import _, {filter, find, map} from 'lodash'
import {fieldTypes, parseMultiLineTextBox} from "./utils";
import axios from "axios";
import {API_URL} from "../config";
import {Box, Button, Chip, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../components/CustomTooltip";
import {AddCircle, Check, Close, ContentCopy, VideoCameraFront} from "@mui/icons-material";

const columnsUrl = (confId) => {
    return `${API_URL}config/columns/${confId||''}`
}

const saveColumsToExport = (confId, columns) => {
    return axios.post(`${columnsUrl()}export`, {[confId]: columns}, )
};

const saveDefaultVisibilityModel = (formId, columns) => {
    return axios.post(`${columnsUrl()}visibility`, {[formId]: {defaultVisibilityModel: columns}}, )
};

const getFieldsFromForm = (form) => {
    let fields = []
    if(form) {
        if (form.sections) {
            form.sections.forEach((section) => {
                fields = fields.concat(map(section.fields, (field) => ({...field, section: section.title})))
            })
            return fields
        } else {
            return form.fields
        }
    }
}

const getColumns = (form, isAdmin) => {
    const cols = filter(getFieldsFromForm(form), function (o) {
        const field = find(fieldTypes, ['type', o.type])
        return !field.onlyUserForm && !field.noDataGrid
    })?.map((field) => {
        let baseField = {
            field: field.id || field,
            headerName: field.headerName || field.label || field.id || (_.startCase(field)),
            flex: 1,
        }

        baseField = field.getter ? {
            ...baseField,
            valueGetter: (params) => {
                return params.row[field.getter]?.[field.id]?._seconds ?
                    new Date(params.row[field.getter]?.[field.id]?._seconds * 1000)
                    : params.row[field.getter]?.[field.id]
            }
        } : {...baseField, valueGetter: (params) => {
                return params.row[field.id]?._seconds ?
                    new Date(params.row[field.id]?._seconds * 1000)
                    : params.row[field.id]
            }}

        const formType = _.find(fieldTypes, ['type', field.type])
        if(!formType.onlyUserForm) {
            switch (formType) {
                case fieldTypes.DATE:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleDateString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.DATE_TIME:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.TIME:
                    return {
                        ...baseField,
                        type: 'dateTime', // TODO: capire
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleTimeString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleTimeString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.CHECK_BOX:
                    return {
                        ...baseField,
                        flex: 0.6,
                        valueGetter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì') {
                                return 'si';
                            } else if (params.value === false || params.value === 'no')
                                return 'no';
                            else return params.value || '-'
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'no')
                                return 'No';
                            else return 'Non specificato'
                        },
                        renderCell: (params) => (params.value === true || params.value === 'si' || params.value === 'sì')
                            ? <Check sx={{color: 'green'}}/>
                            : (params.value === false || params.value === 'no' || params.value === '')
                                ? <Close color={'disabled'}/>
                                : '-'
                    }
                case fieldTypes.CUSTOM:
                    console.log("field.id:",field.id)
                    return {
                        ...baseField,
                        flex: 0.6,
                        valueFormatter: (params) => {
                            if (!!params.value) {
                                return `Punteggio: ${params.value?.total_score} - ${new Date(params.value.datetime?._seconds * 1000).toLocaleDateString()}`;
                            } else return '-';
                        },
                        renderCell: (params) => {
                            const session = (field.id?.toString()).split('lastResult')
                            if(session.length >= 0) {
                                console.log("session:",session[1])
                            }

                            console.log("params:",params.row)
                            return !!params.value ? <Chip label={params.value.total_score}
                                        icon={params.value?.test_passed ? <Check sx={{color: 'green'}}/>
                                            : <Close color={'disabled'}/>}
                                        color={params.value?.test_passed ? 'success' : 'error'}
                                        size={'small'}
                                /> : '-'
                        }
                    }
                case fieldTypes.URL:
                    return {
                        ...baseField,
                        renderCell: (params) => params.value ?
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <CustomTooltip title={"Copia link"}
                                    children={<IconButton size={'small'} color={'primary'}
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    navigator.clipboard.writeText(params.value)
                                                    //.then(() => setMessage({show: true, text: "Link copiato negli appunti", severity: 'success'}))

                                                }}>
                                        <ContentCopy fontSize={'small'}/>
                                    </IconButton>}
                                />
                                <CustomTooltip
                                    title={'Apri link in un\'altra finestra'}
                                    children={<Button href={params.value}
                                                      target={'_blank'}
                                                      color={'accent'}
                                                      size={'small'}
                                                      variant={'contained'}
                                                      sx={{color: 'white'}}
                                                      onClick={(event) => event.stopPropagation()}
                                                      endIcon={<VideoCameraFront/>}>
                                        Link
                                    </Button>}/>
                            </Box> : ""
                    }
                case fieldTypes.ACCESS_TYPE:
                    return {
                        ...baseField,
                        disableExport: true,
                        renderCell: (params) => {
                            /** CAMBIARE streaming */
                            if (params.row.partecipazione === 'si')
                                return <Stack direction={"row"} alignItems={'center'}>
                                    {!!params.row.zoomUrl ?
                                        <CustomTooltip title={"Copia link Zoom personale"} children={
                                            <IconButton
                                                color={'accent'}
                                                variant={'outlined'}
                                                disabled={!params.row.zoomUrl}
                                                onClick={async (event) => {
                                                    event.stopPropagation()
                                                    await navigator.clipboard.writeText(
                                                        params.row.zoomUrl || ``)
                                                }}>
                                                <ContentCopy/>
                                            </IconButton>
                                        }/>
                                        : (isAdmin) ?
                                            <CustomTooltip title={"Genera link Zoom personale"} children={
                                                <IconButton
                                                    color={'accent'}
                                                    variant={'outlined'}
                                                    disabled={params.row.zoomUrl}
                                                    onClick={async (event) => {
                                                        event.stopPropagation()
                                                        axios.post(`${API_URL}zoom`, {id: params.row.id})
                                                            .then((res) => {
                                                                console.log("res:",res)
                                                            })
                                                            .catch((err) => {console.log("err:",err)})
                                                    }}>
                                                    <AddCircle/>
                                                </IconButton>
                                            }/>
                                            : null
                                    }
                                    <CustomTooltip title={"Apri link zoom personale"} children={
                                        <IconButton href={params.row.zoomUrl || ``}
                                                    size={"small"}
                                                    target={'_blank'}
                                                    color={'accent'}
                                                    disabled={!params.row.zoomUrl}
                                                    onClick={async (event) => {
                                                        event.stopPropagation()
                                                    }}
                                        >
                                            <VideoCameraFront/>
                                        </IconButton>
                                    }/>
                                </Stack>
                            else if (params.row.partecipazione === 'no')
                                return <Close color={'disabled'}/>
                        }
                    }
                case fieldTypes.RADIO_GROUP:
                    return {
                        ...baseField,
                        //flex: 0.6,
                        valueGetter: (params) => {
                            if (params.value === 'si') {
                                return 'si';
                            } else if (params.value === 'no')
                                return 'no';
                            else {
                                const label = find(field.values, ['value', params.value])?.label
                                return label && label !== "Nessuna delle precedenti" ? label : params.value
                            }
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì' || params.value === 'partecipo') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'no' || params.value === 'nonPartecipo')
                                return 'No';
                            else {
                                const label = find(field.values, ['value', params.value])?.label
                                return label && label !== "Nessuna delle precedenti" ? label : params.value
                            }
                        },
                        renderCell: (params) => {
                            return (params.value === true
                                || params.value?.toLowerCase() === 'si' || params.value?.toLowerCase() === 'sì'
                                || params.value === 'partecipo')
                                ? <Stack direction={'row'} spacing={1}><Check sx={{color: 'green'}}/></Stack>
                                : (params.value === false || params.value?.toLowerCase() === 'no' || params.value === 'nonPartecipo')
                                    ? <Stack direction={'row'} spacing={1}><Close color={'disabled'}/></Stack>
                                    : (find(field.values, ['value', params.value])?.label || params.value || '-')
                        }
                    }
                case fieldTypes.SELECT:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            const label = find(field.values, ['value', params.value])?.label
                            return label && label !== "Nessuna" ? label : params.value
                        },
                    }
                case fieldTypes.TEXT_BOX:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            return parseMultiLineTextBox(params.value)
                        },
                    }
                default:
                    return baseField
            }
        }
    })

    return cols;
};

const ColumnsService = {
    columnsUrl,
    getColumns,
    saveColumsToExport,
    saveDefaultVisibilityModel,
    getFieldsFromForm
};

export default ColumnsService;


export const commonFields = [
    {
        "id": "nome",
        "label": "Nome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "cognome",
        "label": "Cognome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "email",
        "label": "E-mail",
        "type": "email",
        "precompiled": true,
        "defaultVisible": true
    }
]