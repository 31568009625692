import {CssBaseline, ThemeProvider} from "@mui/material";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import './App.css';
import {customTheme} from "./theme/customTheme";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import Iscrizioni from "./pages/iscritti/Iscrizioni";
import axios from "axios";
import Home from "./pages/Home";
import Settings from "./pages/settings/Settings";
import {GlobalContext} from "./state/global";
import {useEffect, useReducer, useState} from "react";
import Logger from "./state/Logger";
import {globalReducer, initializeState} from "./state/global/globalReducer";
import UserDetails from "./pages/iscritti/UserDetails";
import CheckIn from "./pages/CheckIn";
import {auth} from "./firebase/clientApp";
import jwt_decode from "jwt-decode";

const AppUI = () => {
    document.body.style.backgroundColor = '#FFFFFF';

    const [user, setUser] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            //console.log("user:", user)
            const token = await user?.getIdToken()

            if (token) {
                const role = jwt_decode(token)?.role
                console.log("role:", role)
                if(!!role) {
                    setUser(user)
                } else {
                    navigate('/login', {replace: true})
                }
            } else {
                navigate('/login', {replace: true})
            }
        })
    },[])

    return (<>
            <Routes>
                <Route path={'/login'} element={<Login/>}/>
                {user && <Route element={<ProtectedRoute user={user}/>}>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/iscrizioni'} element={<Iscrizioni/>}/>
                    <Route path={'/iscrizioni/user/:id'} element={<UserDetails/>}/>
                    <Route path={'/settings'} element={<Settings/>}/>
                </Route>}
            </Routes>
    </>)
}

const App = () => {
    const currentLocation = useLocation().pathname
    axios.interceptors.request.use(
        async config => {
            const token = await auth.currentUser?.getIdToken()
            if (token) {
                config.headers.authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    );

    return (
        <GlobalContext.Provider
            value={useReducer(Logger(globalReducer), {}, () => initializeState({currentLocation: currentLocation}))}
            displayName="Global Context">
            <ThemeProvider theme={customTheme}>
                <CssBaseline/>
                <Routes>
                    <Route path={'/checkin'} element={<CheckIn/>}/>
                    <Route path={'/*'} element={<AppUI/>}/>
                </Routes>
            </ThemeProvider>
        </GlobalContext.Provider>
    );
}

export default App;
