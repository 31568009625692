import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import React, {useMemo} from "react";
import useAxios from "axios-hooks";
import UserService from "../../services/user.service";
import _ from "lodash";
import {Cancel, Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CustomBackdrop from "../../components/CustomBackdrop";
import CustomTooltip from "../../components/CustomTooltip";
import {getLocaleDateTime} from "../../services/utils";
import UserDetailsMenu from "./UserDetailsMenu";
import CustomDialogTitle from "../../components/CustomDialogTitle";

const ArchivedUsersDialog = ({session_id, openArchivedDialog, setOpenArchivedDialog, refetch}) => {
    //console.log("ArchivedUsersDialog > session_id:",session_id)
    const [{data: allUsers, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl({session: session_id, archived: true}), {method: "GET", useCache: false, manual: false}
    )

    const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
        {manual: true}
    )

    const users = useMemo(() => {
        if (!allUsers)
            return []
        else return _.orderBy(allUsers.filter(function (o) {
            return true //!o.roles || o.test === true// && !o.test
        }), 'updatedAt._seconds', 'desc')
    }, [allUsers])

    const handleCloseDialog = () => {
        setOpenArchivedDialog(false)
    }

    return (
        <Dialog open={openArchivedDialog} onClose={handleCloseDialog} fullWidth maxWidth={'sm'}>
            <CustomDialogTitle title={'Utenti Archiviati'} handleCloseDialog={handleCloseDialog}/>
            <DialogContent>
                <CustomBackdrop open={loadingUsers}/>
                {users.length === 0 && <Typography>Nessun utente archiviato</Typography>}
                <List>
                    {
                        users.map((archivedUser, i) => (
                            <ListItem key={i}>
                                <ListItemText primary={`${archivedUser.nome} ${archivedUser.cognome}`}
                                              secondary={archivedUser.email}
                                />
                                <ListItemSecondaryAction children={
                                    <>
                                        {archivedUser?.updatedAt ?
                                            <Chip size={'small'}
                                                  label={`Archiviato il ${getLocaleDateTime({ts_seconds: archivedUser?.updatedAt?._seconds})}`}
                                            />
                                            :  null}
                                        <CustomTooltip title={`Ripristina ${archivedUser.nome} ${archivedUser.cognome}`}
                                                       children={<UserDetailsMenu
                                                                               session={session_id}
                                                                               userId={archivedUser.id}
                                                                               archive={false}
                                                                               updateUser={updateUser}
                                                                               onSuccess={() => {
                                                                                   fetchUsers()
                                                                                   refetch()
                                                                               }}
                                                       />}/>
                                    </>
                                }/>
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Chiudi</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ArchivedUsersDialog