import * as React from 'react';
import {useMemo, useState} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import _, {startCase} from 'lodash'
import {Box, Chip} from "@mui/material";
import {
    AirlineStops, Cancel, Check, CheckCircle, Clear,
    Drafts,
    ExpandLess,
    ExpandMore,
    MarkEmailUnread,
    PersonAdd,
    PersonRemove,
    TouchApp
} from "@mui/icons-material";
import dayjs from "dayjs";
import {sessions} from "../../services/utils";

const actions = {
    'sent': {
        icon: <MarkEmailUnread/>,
        subtitle: "campaign_title"
    },
    'open': {
        icon: <Drafts/>,
        variant: 'outlined'
    },
    'click': {
        icon: <TouchApp/>,
        subtitle: "link_clicked"
    },
    'unsub': {
        icon: <PersonRemove/>,
        variant: 'outlined'
    },
    'generic_signup': {
        icon: <PersonAdd/>,
        subtitle: "signup_category"
    },
    'bounce': {
        icon: <AirlineStops/>,
        subtitle: "bounce_type",
    },
}

export default function QuizResultsTimeline({results}) {
    if(_.isEmpty(results))
        return <Typography align={"center"} variant={"body2"}>Nessuna attività</Typography>

    return (<Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <Timeline>
            {
                results?.map((item, i) =>
                    item && Object.keys(item).length > 0 &&
                    <TimelineItem key={i}>
                        <TimelineOppositeContent
                            sx={{m: 'auto 0'}}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                        >
                            {dayjs(item.datetime._seconds * 1000).format("DD/MM/YY, HH:mm")}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector/>
                            <TimelineDot color={item.test_passed ? 'success' : 'accent'}
                                         variant={item.test_passed ? 'filled' : 'outlined'}>
                                {item.test_passed ? <Check /> : <Clear color={'accent'}/>}
                            </TimelineDot>
                            <TimelineConnector/>
                        </TimelineSeparator>
                        <TimelineContent sx={{py: '12px', px: 2}}>
                            <Typography color={'primary'} component="span">
                                Punteggio: <strong>{startCase(item.total_score)}</strong>
                            </Typography>
                            {/*<Typography variant={"subtitle2"} fontWeight={'lighter'}>
                                    {_.startCase(item.activity_type)} {item.activity_type === "click" ?
                                    item.url.startsWith('http') ?
                                        <Link href={item.url} target={"_blank"}>{item.url}</Link>
                                        : "Custom link"
                                    : ""}
                                </Typography>*/}
                            <Typography variant={"subtitle2"} fontWeight={'lighter'}>
                                {item.test_passed? "Superato" : "Non superato"}
                            </Typography>
                            <Typography variant={"subtitle2"} fontWeight={'lighter'}>
                                {
                                    sessions[item.session_id]?.label
                                }
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                )
            }
        </Timeline>
    </Box>);
}