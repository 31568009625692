export const SET_SELECTED_ROUTE = "SET_SELECTED_ROUTE";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_DATA_STRUCTURES = "SET_DATA_STRUCTURES";
export const SET_DATA_STRUCTURE = "SET_DATA_STRUCTURE";

export const setSelectedRoute = (selectedRoute) => ({type: SET_SELECTED_ROUTE, selectedRoute})

export const setGlobalSettings = (settings, webinars) => ({type: SET_SETTINGS, settings, webinars})

export const setDataStructures = (dataStructures) => ({type: SET_DATA_STRUCTURES, dataStructures})
export const setDataStructure = (dataStructure) => ({type: SET_DATA_STRUCTURE, dataStructure})
